























































import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DateFormatConfig from '@/config/default/dateFormat'
import { userMapper } from '@/store/modules/user'
import WarningModal from '@/components/ui/WarningModal.vue'
import { projectStatusTranslation } from '@/config/projects/status'
import { imagePreview } from '@/config/default/defaultImages'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated', 'currentUser'])
  }
})

@Component({ components: { WarningModal } })
export default class ProjectCard extends Mappers {
  @Prop() project: any

  private projectStatusTranslation_ = projectStatusTranslation
  private imagePreview_ = imagePreview

  get formattedDate(): string {
    return this.project?.updatedAt
      ? moment(this.project.updatedAt).format(DateFormatConfig.date.localFormat)
      : ''
  }
  get truncatedDescription(): string {
    return this.project?.shortDescription?.length > 1000
      ? this.project.shortDescription.slice(0, 1000) + '...'
      : this.project?.shortDescription || ''
  }
}
